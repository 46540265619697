import { createContext as Cr, useMemo as Rr, useEffect as Lr, useContext as Dr } from "react";
import { j as qr } from "./jsx-runtime-DmTjM30f.js";
import { c as ar, g as Mr } from "./_commonjsHelpers-DaMA6jEr.js";
var kr = function(c, p, B) {
  const g = '{"Events":[', d = "]}", x = new c([g]).size + new c([d]).size;
  class y {
    constructor(s) {
      let m = {
        serverUrl: "http://localhost:5341",
        apiKey: null,
        maxBatchingTime: 2e3,
        eventSizeLimit: 262144,
        batchSizeLimit: 1048576,
        requestTimeout: 3e4,
        maxRetries: 5,
        retryDelay: 5e3,
        onError: (v) => {
          console.error("[seq]", v);
        }
      }, h = s || m;
      var l = h.serverUrl || m.serverUrl;
      l.endsWith("/") || (l += "/"), this._endpoint = l + "api/events/raw", this._apiKey = h.apiKey || m.apiKey, this._maxBatchingTime = h.maxBatchingTime || m.maxBatchingTime, this._eventSizeLimit = h.eventSizeLimit || m.eventSizeLimit, this._batchSizeLimit = h.batchSizeLimit || m.batchSizeLimit, this._requestTimeout = h.requestTimeout || m.requestTimeout, this._onError = h.onError || m.onError, this._maxRetries = h.maxRetries || m.maxRetries, this._retryDelay = h.retryDelay || m.retryDelay, this._queue = [], this._timer = null, this._closed = !1, this._activeShipper = null;
    }
    /**
     * Flush events queued at the time of the call, and wait for pending writes to complete regardless of configured batching/timers.
     * @returns {Promise<boolean>}
     */
    flush() {
      return this._ship();
    }
    /**
     * Flush then destroy connections, close the logger, destroying timers and other resources.
     * @returns {Promise<void>}
     */
    close() {
      if (this._closed)
        throw new Error("The logger has already been closed.");
      return this._closed = !0, this._clearTimer(), this.flush();
    }
    /**
     * Enqueue an event in Seq format.
     * @param {*} event
     * @returns {void}
     */
    emit(s) {
      if (!s)
        throw new Error("An event must be provided");
      if (this._closed)
        return;
      let m = this._toWireFormat(s);
      this._queue.push(m), this._activeShipper || this._setTimer();
    }
    _setTimer() {
      this._timer === null && (this._timer = setTimeout(() => {
        this._timer = null, this._onTimer();
      }, this._maxBatchingTime));
    }
    _clearTimer() {
      this._timer !== null && (clearTimeout(this._timer), this._timer = null);
    }
    _onTimer() {
      this._activeShipper || this._ship();
    }
    _toWireFormat(s) {
      var w, E;
      const m = typeof s.level == "string" ? s.level : void 0, h = s.timestamp instanceof Date ? s.timestamp : /* @__PURE__ */ new Date(), l = typeof s.messageTemplate == "string" ? s.messageTemplate : s.messageTemplate !== null && s.messageTemplate !== void 0 ? s.messageTemplate.toString() : "(No message provided)", v = typeof s.exception == "string" ? s.exception : s.exception !== null && s.exception !== void 0 ? s.exception.toString() : void 0, f = typeof s.properties == "object" ? s.properties : void 0;
      return {
        Timestamp: h,
        Level: m,
        TraceId: (w = s.traceId) == null ? void 0 : w.toString(),
        SpanId: (E = s.spanId) == null ? void 0 : E.toString(),
        MessageTemplate: l,
        Exception: v,
        Properties: f
      };
    }
    _eventTooLargeErrorEvent(s) {
      return {
        Timestamp: s.Timestamp,
        Level: s.Level,
        MessageTemplate: "(Event too large) {initial}...",
        Properties: {
          initial: s.MessageTemplate.substring(0, 12),
          sourceContext: "Seq Javascript Client",
          eventSizeLimit: this._eventSizeLimit
        }
      };
    }
    _reset(s) {
      this._activeShipper === s && (this._activeShipper = null, this._queue.length !== 0 && this._setTimer());
    }
    /**
     *
     * @returns {Promise<boolean>}
     */
    _ship() {
      if (this._queue.length === 0)
        return Promise.resolve(!1);
      let s = this._activeShipper || Promise.resolve(!1), m = this._activeShipper = s.then(() => {
        let h = (l) => l ? !0 : this._sendBatch().then((v) => h(v));
        return this._sendBatch().then((l) => h(l));
      }).then(() => this._reset(m), (h) => {
        this._onError(h), this._reset(m);
      });
      return m;
    }
    _sendBatch() {
      if (this._queue.length === 0)
        return Promise.resolve(!0);
      let s = this._dequeBatch(), m = this._queue.length === 0;
      return this._post(s.batch, s.bytes).then(() => m);
    }
    _dequeBatch() {
      var s = x;
      let m = [];
      for (var h = 0, l = 0; h < this._queue.length; ) {
        let f = this._queue[h], w;
        try {
          w = JSON.stringify(f);
        } catch {
          const U = G(f);
          w = JSON.stringify(U), this.emit({
            timestamp: U.Timestamp,
            level: "Error",
            messageTemplate: "[seq] Circular structure found"
          });
        }
        var v = new c([w]).size;
        if (v > this._eventSizeLimit && (this._onError("[seq] Event body is larger than " + this._eventSizeLimit + " bytes: " + w), this._queue[h] = f = this._eventTooLargeErrorEvent(f), w = JSON.stringify(f), v = new c([w]).size), h !== 0 && s + v + l > this._batchSizeLimit)
          break;
        h = h + 1, s += v + l, l = 1, m.push(w);
      }
      return this._queue.splice(0, h), { batch: m, bytes: s };
    }
    _httpOrNetworkError(s) {
      return ["ECONNRESET", "ENOTFOUND", "ESOCKETTIMEDOUT", "ETIMEDOUT", "ECONNREFUSED", "EHOSTUNREACH", "EPIPE", "EAI_AGAIN", "EBUSY"].includes(s) || 500 <= s.status && s.status < 600;
    }
    _post(s, m) {
      let h = 0;
      return new Promise((l, v) => {
        const f = (w, E) => {
          const U = new B();
          h++;
          const C = setTimeout(() => {
            U.abort(), h > this._maxRetries ? v("HTTP log shipping failed, reached timeout (" + this._requestTimeout + " ms)") : setTimeout(() => f(w, E), this._retryDelay);
          }, this._requestTimeout);
          p(this._endpoint, {
            keepalive: !0,
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-Seq-ApiKey": this._apiKey ? this._apiKey : null,
              "Content-Length": E
            },
            body: `${g}${w.join(",")}${d}`,
            signal: U.signal
          }).then((A) => {
            clearTimeout(C);
            let R = null;
            return A.status !== 200 && A.status !== 201 ? (R = "HTTP log shipping failed: " + A.status, this._httpOrNetworkError(A) && h < this._maxRetries ? setTimeout(() => f(w, E), this._retryDelay) : v(R)) : l(!0);
          }).catch((A) => {
            clearTimeout(C), v(A);
          });
        };
        return f(s, m);
      });
    }
  }
  return y;
};
const X = (c) => !c || typeof c != "object", G = (c, p = /* @__PURE__ */ new Map(), B = "root") => {
  if (X(c)) return c;
  if (p.has(c))
    return "== Circular structure: '" + p.get(c).replace("root.Properties.", "") + "' ==";
  if (p.set(c, B), c instanceof Array)
    return c.map(
      (x, y) => X(x) ? x : G(x, new Map(p), B + `[${y}]`)
    );
  const g = Object.keys(c);
  if (g.length === 0)
    return c;
  const d = {};
  return g.forEach((x) => {
    const y = c[x];
    if (X(y)) {
      d[x] = y;
      return;
    }
    d[x] = G(y, new Map(p), B + "." + x);
  }), d;
};
var V = {}, b = {}, hr;
function Nr() {
  if (hr) return b;
  hr = 1, b.byteLength = o, b.toByteArray = m, b.fromByteArray = v;
  for (var c = [], p = [], B = typeof Uint8Array < "u" ? Uint8Array : Array, g = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/", d = 0, x = g.length; d < x; ++d)
    c[d] = g[d], p[g.charCodeAt(d)] = d;
  p[45] = 62, p[95] = 63;
  function y(f) {
    var w = f.length;
    if (w % 4 > 0)
      throw new Error("Invalid string. Length must be a multiple of 4");
    var E = f.indexOf("=");
    E === -1 && (E = w);
    var U = E === w ? 0 : 4 - E % 4;
    return [E, U];
  }
  function o(f) {
    var w = y(f), E = w[0], U = w[1];
    return (E + U) * 3 / 4 - U;
  }
  function s(f, w, E) {
    return (w + E) * 3 / 4 - E;
  }
  function m(f) {
    var w, E = y(f), U = E[0], C = E[1], A = new B(s(f, U, C)), R = 0, k = C > 0 ? U - 4 : U, S;
    for (S = 0; S < k; S += 4)
      w = p[f.charCodeAt(S)] << 18 | p[f.charCodeAt(S + 1)] << 12 | p[f.charCodeAt(S + 2)] << 6 | p[f.charCodeAt(S + 3)], A[R++] = w >> 16 & 255, A[R++] = w >> 8 & 255, A[R++] = w & 255;
    return C === 2 && (w = p[f.charCodeAt(S)] << 2 | p[f.charCodeAt(S + 1)] >> 4, A[R++] = w & 255), C === 1 && (w = p[f.charCodeAt(S)] << 10 | p[f.charCodeAt(S + 1)] << 4 | p[f.charCodeAt(S + 2)] >> 2, A[R++] = w >> 8 & 255, A[R++] = w & 255), A;
  }
  function h(f) {
    return c[f >> 18 & 63] + c[f >> 12 & 63] + c[f >> 6 & 63] + c[f & 63];
  }
  function l(f, w, E) {
    for (var U, C = [], A = w; A < E; A += 3)
      U = (f[A] << 16 & 16711680) + (f[A + 1] << 8 & 65280) + (f[A + 2] & 255), C.push(h(U));
    return C.join("");
  }
  function v(f) {
    for (var w, E = f.length, U = E % 3, C = [], A = 16383, R = 0, k = E - U; R < k; R += A)
      C.push(l(f, R, R + A > k ? k : R + A));
    return U === 1 ? (w = f[E - 1], C.push(
      c[w >> 2] + c[w << 4 & 63] + "=="
    )) : U === 2 && (w = (f[E - 2] << 8) + f[E - 1], C.push(
      c[w >> 10] + c[w >> 4 & 63] + c[w << 2 & 63] + "="
    )), C.join("");
  }
  return b;
}
var H = {};
/*! ieee754. BSD-3-Clause License. Feross Aboukhadijeh <https://feross.org/opensource> */
var sr;
function br() {
  return sr || (sr = 1, H.read = function(c, p, B, g, d) {
    var x, y, o = d * 8 - g - 1, s = (1 << o) - 1, m = s >> 1, h = -7, l = B ? d - 1 : 0, v = B ? -1 : 1, f = c[p + l];
    for (l += v, x = f & (1 << -h) - 1, f >>= -h, h += o; h > 0; x = x * 256 + c[p + l], l += v, h -= 8)
      ;
    for (y = x & (1 << -h) - 1, x >>= -h, h += g; h > 0; y = y * 256 + c[p + l], l += v, h -= 8)
      ;
    if (x === 0)
      x = 1 - m;
    else {
      if (x === s)
        return y ? NaN : (f ? -1 : 1) * (1 / 0);
      y = y + Math.pow(2, g), x = x - m;
    }
    return (f ? -1 : 1) * y * Math.pow(2, x - g);
  }, H.write = function(c, p, B, g, d, x) {
    var y, o, s, m = x * 8 - d - 1, h = (1 << m) - 1, l = h >> 1, v = d === 23 ? Math.pow(2, -24) - Math.pow(2, -77) : 0, f = g ? 0 : x - 1, w = g ? 1 : -1, E = p < 0 || p === 0 && 1 / p < 0 ? 1 : 0;
    for (p = Math.abs(p), isNaN(p) || p === 1 / 0 ? (o = isNaN(p) ? 1 : 0, y = h) : (y = Math.floor(Math.log(p) / Math.LN2), p * (s = Math.pow(2, -y)) < 1 && (y--, s *= 2), y + l >= 1 ? p += v / s : p += v * Math.pow(2, 1 - l), p * s >= 2 && (y++, s /= 2), y + l >= h ? (o = 0, y = h) : y + l >= 1 ? (o = (p * s - 1) * Math.pow(2, d), y = y + l) : (o = p * Math.pow(2, l - 1) * Math.pow(2, d), y = 0)); d >= 8; c[B + f] = o & 255, f += w, o /= 256, d -= 8)
      ;
    for (y = y << d | o, m += d; m > 0; c[B + f] = y & 255, f += w, y /= 256, m -= 8)
      ;
    c[B + f - w] |= E * 128;
  }), H;
}
/*!
 * The buffer module from node.js, for the browser.
 *
 * @author   Feross Aboukhadijeh <https://feross.org>
 * @license  MIT
 */
var fr;
function Or() {
  return fr || (fr = 1, function(c) {
    var p = Nr(), B = br(), g = typeof Symbol == "function" && typeof Symbol.for == "function" ? Symbol.for("nodejs.util.inspect.custom") : null;
    c.Buffer = o, c.SlowBuffer = A, c.INSPECT_MAX_BYTES = 50;
    var d = 2147483647;
    c.kMaxLength = d, o.TYPED_ARRAY_SUPPORT = x(), !o.TYPED_ARRAY_SUPPORT && typeof console < "u" && typeof console.error == "function" && console.error(
      "This browser lacks typed array (Uint8Array) support which is required by `buffer` v5.x. Use `buffer` v4.x if you require old browser support."
    );
    function x() {
      try {
        var t = new Uint8Array(1), r = { foo: function() {
          return 42;
        } };
        return Object.setPrototypeOf(r, Uint8Array.prototype), Object.setPrototypeOf(t, r), t.foo() === 42;
      } catch {
        return !1;
      }
    }
    Object.defineProperty(o.prototype, "parent", {
      enumerable: !0,
      get: function() {
        if (o.isBuffer(this))
          return this.buffer;
      }
    }), Object.defineProperty(o.prototype, "offset", {
      enumerable: !0,
      get: function() {
        if (o.isBuffer(this))
          return this.byteOffset;
      }
    });
    function y(t) {
      if (t > d)
        throw new RangeError('The value "' + t + '" is invalid for option "size"');
      var r = new Uint8Array(t);
      return Object.setPrototypeOf(r, o.prototype), r;
    }
    function o(t, r, e) {
      if (typeof t == "number") {
        if (typeof r == "string")
          throw new TypeError(
            'The "string" argument must be of type string. Received type number'
          );
        return l(t);
      }
      return s(t, r, e);
    }
    o.poolSize = 8192;
    function s(t, r, e) {
      if (typeof t == "string")
        return v(t, r);
      if (ArrayBuffer.isView(t))
        return w(t);
      if (t == null)
        throw new TypeError(
          "The first argument must be one of type string, Buffer, ArrayBuffer, Array, or Array-like Object. Received type " + typeof t
        );
      if (M(t, ArrayBuffer) || t && M(t.buffer, ArrayBuffer) || typeof SharedArrayBuffer < "u" && (M(t, SharedArrayBuffer) || t && M(t.buffer, SharedArrayBuffer)))
        return E(t, r, e);
      if (typeof t == "number")
        throw new TypeError(
          'The "value" argument must not be of type number. Received type number'
        );
      var i = t.valueOf && t.valueOf();
      if (i != null && i !== t)
        return o.from(i, r, e);
      var n = U(t);
      if (n) return n;
      if (typeof Symbol < "u" && Symbol.toPrimitive != null && typeof t[Symbol.toPrimitive] == "function")
        return o.from(
          t[Symbol.toPrimitive]("string"),
          r,
          e
        );
      throw new TypeError(
        "The first argument must be one of type string, Buffer, ArrayBuffer, Array, or Array-like Object. Received type " + typeof t
      );
    }
    o.from = function(t, r, e) {
      return s(t, r, e);
    }, Object.setPrototypeOf(o.prototype, Uint8Array.prototype), Object.setPrototypeOf(o, Uint8Array);
    function m(t) {
      if (typeof t != "number")
        throw new TypeError('"size" argument must be of type number');
      if (t < 0)
        throw new RangeError('The value "' + t + '" is invalid for option "size"');
    }
    function h(t, r, e) {
      return m(t), t <= 0 ? y(t) : r !== void 0 ? typeof e == "string" ? y(t).fill(r, e) : y(t).fill(r) : y(t);
    }
    o.alloc = function(t, r, e) {
      return h(t, r, e);
    };
    function l(t) {
      return m(t), y(t < 0 ? 0 : C(t) | 0);
    }
    o.allocUnsafe = function(t) {
      return l(t);
    }, o.allocUnsafeSlow = function(t) {
      return l(t);
    };
    function v(t, r) {
      if ((typeof r != "string" || r === "") && (r = "utf8"), !o.isEncoding(r))
        throw new TypeError("Unknown encoding: " + r);
      var e = R(t, r) | 0, i = y(e), n = i.write(t, r);
      return n !== e && (i = i.slice(0, n)), i;
    }
    function f(t) {
      for (var r = t.length < 0 ? 0 : C(t.length) | 0, e = y(r), i = 0; i < r; i += 1)
        e[i] = t[i] & 255;
      return e;
    }
    function w(t) {
      if (M(t, Uint8Array)) {
        var r = new Uint8Array(t);
        return E(r.buffer, r.byteOffset, r.byteLength);
      }
      return f(t);
    }
    function E(t, r, e) {
      if (r < 0 || t.byteLength < r)
        throw new RangeError('"offset" is outside of buffer bounds');
      if (t.byteLength < r + (e || 0))
        throw new RangeError('"length" is outside of buffer bounds');
      var i;
      return r === void 0 && e === void 0 ? i = new Uint8Array(t) : e === void 0 ? i = new Uint8Array(t, r) : i = new Uint8Array(t, r, e), Object.setPrototypeOf(i, o.prototype), i;
    }
    function U(t) {
      if (o.isBuffer(t)) {
        var r = C(t.length) | 0, e = y(r);
        return e.length === 0 || t.copy(e, 0, 0, r), e;
      }
      if (t.length !== void 0)
        return typeof t.length != "number" || $(t.length) ? y(0) : f(t);
      if (t.type === "Buffer" && Array.isArray(t.data))
        return f(t.data);
    }
    function C(t) {
      if (t >= d)
        throw new RangeError("Attempt to allocate Buffer larger than maximum size: 0x" + d.toString(16) + " bytes");
      return t | 0;
    }
    function A(t) {
      return +t != t && (t = 0), o.alloc(+t);
    }
    o.isBuffer = function(r) {
      return r != null && r._isBuffer === !0 && r !== o.prototype;
    }, o.compare = function(r, e) {
      if (M(r, Uint8Array) && (r = o.from(r, r.offset, r.byteLength)), M(e, Uint8Array) && (e = o.from(e, e.offset, e.byteLength)), !o.isBuffer(r) || !o.isBuffer(e))
        throw new TypeError(
          'The "buf1", "buf2" arguments must be one of type Buffer or Uint8Array'
        );
      if (r === e) return 0;
      for (var i = r.length, n = e.length, u = 0, a = Math.min(i, n); u < a; ++u)
        if (r[u] !== e[u]) {
          i = r[u], n = e[u];
          break;
        }
      return i < n ? -1 : n < i ? 1 : 0;
    }, o.isEncoding = function(r) {
      switch (String(r).toLowerCase()) {
        case "hex":
        case "utf8":
        case "utf-8":
        case "ascii":
        case "latin1":
        case "binary":
        case "base64":
        case "ucs2":
        case "ucs-2":
        case "utf16le":
        case "utf-16le":
          return !0;
        default:
          return !1;
      }
    }, o.concat = function(r, e) {
      if (!Array.isArray(r))
        throw new TypeError('"list" argument must be an Array of Buffers');
      if (r.length === 0)
        return o.alloc(0);
      var i;
      if (e === void 0)
        for (e = 0, i = 0; i < r.length; ++i)
          e += r[i].length;
      var n = o.allocUnsafe(e), u = 0;
      for (i = 0; i < r.length; ++i) {
        var a = r[i];
        if (M(a, Uint8Array))
          u + a.length > n.length ? o.from(a).copy(n, u) : Uint8Array.prototype.set.call(
            n,
            a,
            u
          );
        else if (o.isBuffer(a))
          a.copy(n, u);
        else
          throw new TypeError('"list" argument must be an Array of Buffers');
        u += a.length;
      }
      return n;
    };
    function R(t, r) {
      if (o.isBuffer(t))
        return t.length;
      if (ArrayBuffer.isView(t) || M(t, ArrayBuffer))
        return t.byteLength;
      if (typeof t != "string")
        throw new TypeError(
          'The "string" argument must be one of type string, Buffer, or ArrayBuffer. Received type ' + typeof t
        );
      var e = t.length, i = arguments.length > 2 && arguments[2] === !0;
      if (!i && e === 0) return 0;
      for (var n = !1; ; )
        switch (r) {
          case "ascii":
          case "latin1":
          case "binary":
            return e;
          case "utf8":
          case "utf-8":
            return Y(t).length;
          case "ucs2":
          case "ucs-2":
          case "utf16le":
          case "utf-16le":
            return e * 2;
          case "hex":
            return e >>> 1;
          case "base64":
            return nr(t).length;
          default:
            if (n)
              return i ? -1 : Y(t).length;
            r = ("" + r).toLowerCase(), n = !0;
        }
    }
    o.byteLength = R;
    function k(t, r, e) {
      var i = !1;
      if ((r === void 0 || r < 0) && (r = 0), r > this.length || ((e === void 0 || e > this.length) && (e = this.length), e <= 0) || (e >>>= 0, r >>>= 0, e <= r))
        return "";
      for (t || (t = "utf8"); ; )
        switch (t) {
          case "hex":
            return vr(this, r, e);
          case "utf8":
          case "utf-8":
            return j(this, r, e);
          case "ascii":
            return Fr(this, r, e);
          case "latin1":
          case "binary":
            return Br(this, r, e);
          case "base64":
            return gr(this, r, e);
          case "ucs2":
          case "ucs-2":
          case "utf16le":
          case "utf-16le":
            return Tr(this, r, e);
          default:
            if (i) throw new TypeError("Unknown encoding: " + t);
            t = (t + "").toLowerCase(), i = !0;
        }
    }
    o.prototype._isBuffer = !0;
    function S(t, r, e) {
      var i = t[r];
      t[r] = t[e], t[e] = i;
    }
    o.prototype.swap16 = function() {
      var r = this.length;
      if (r % 2 !== 0)
        throw new RangeError("Buffer size must be a multiple of 16-bits");
      for (var e = 0; e < r; e += 2)
        S(this, e, e + 1);
      return this;
    }, o.prototype.swap32 = function() {
      var r = this.length;
      if (r % 4 !== 0)
        throw new RangeError("Buffer size must be a multiple of 32-bits");
      for (var e = 0; e < r; e += 4)
        S(this, e, e + 3), S(this, e + 1, e + 2);
      return this;
    }, o.prototype.swap64 = function() {
      var r = this.length;
      if (r % 8 !== 0)
        throw new RangeError("Buffer size must be a multiple of 64-bits");
      for (var e = 0; e < r; e += 8)
        S(this, e, e + 7), S(this, e + 1, e + 6), S(this, e + 2, e + 5), S(this, e + 3, e + 4);
      return this;
    }, o.prototype.toString = function() {
      var r = this.length;
      return r === 0 ? "" : arguments.length === 0 ? j(this, 0, r) : k.apply(this, arguments);
    }, o.prototype.toLocaleString = o.prototype.toString, o.prototype.equals = function(r) {
      if (!o.isBuffer(r)) throw new TypeError("Argument must be a Buffer");
      return this === r ? !0 : o.compare(this, r) === 0;
    }, o.prototype.inspect = function() {
      var r = "", e = c.INSPECT_MAX_BYTES;
      return r = this.toString("hex", 0, e).replace(/(.{2})/g, "$1 ").trim(), this.length > e && (r += " ... "), "<Buffer " + r + ">";
    }, g && (o.prototype[g] = o.prototype.inspect), o.prototype.compare = function(r, e, i, n, u) {
      if (M(r, Uint8Array) && (r = o.from(r, r.offset, r.byteLength)), !o.isBuffer(r))
        throw new TypeError(
          'The "target" argument must be one of type Buffer or Uint8Array. Received type ' + typeof r
        );
      if (e === void 0 && (e = 0), i === void 0 && (i = r ? r.length : 0), n === void 0 && (n = 0), u === void 0 && (u = this.length), e < 0 || i > r.length || n < 0 || u > this.length)
        throw new RangeError("out of range index");
      if (n >= u && e >= i)
        return 0;
      if (n >= u)
        return -1;
      if (e >= i)
        return 1;
      if (e >>>= 0, i >>>= 0, n >>>= 0, u >>>= 0, this === r) return 0;
      for (var a = u - n, F = i - e, T = Math.min(a, F), _ = this.slice(n, u), D = r.slice(e, i), I = 0; I < T; ++I)
        if (_[I] !== D[I]) {
          a = _[I], F = D[I];
          break;
        }
      return a < F ? -1 : F < a ? 1 : 0;
    };
    function Z(t, r, e, i, n) {
      if (t.length === 0) return -1;
      if (typeof e == "string" ? (i = e, e = 0) : e > 2147483647 ? e = 2147483647 : e < -2147483648 && (e = -2147483648), e = +e, $(e) && (e = n ? 0 : t.length - 1), e < 0 && (e = t.length + e), e >= t.length) {
        if (n) return -1;
        e = t.length - 1;
      } else if (e < 0)
        if (n) e = 0;
        else return -1;
      if (typeof r == "string" && (r = o.from(r, i)), o.isBuffer(r))
        return r.length === 0 ? -1 : Q(t, r, e, i, n);
      if (typeof r == "number")
        return r = r & 255, typeof Uint8Array.prototype.indexOf == "function" ? n ? Uint8Array.prototype.indexOf.call(t, r, e) : Uint8Array.prototype.lastIndexOf.call(t, r, e) : Q(t, [r], e, i, n);
      throw new TypeError("val must be string, number or Buffer");
    }
    function Q(t, r, e, i, n) {
      var u = 1, a = t.length, F = r.length;
      if (i !== void 0 && (i = String(i).toLowerCase(), i === "ucs2" || i === "ucs-2" || i === "utf16le" || i === "utf-16le")) {
        if (t.length < 2 || r.length < 2)
          return -1;
        u = 2, a /= 2, F /= 2, e /= 2;
      }
      function T(or, ur) {
        return u === 1 ? or[ur] : or.readUInt16BE(ur * u);
      }
      var _;
      if (n) {
        var D = -1;
        for (_ = e; _ < a; _++)
          if (T(t, _) === T(r, D === -1 ? 0 : _ - D)) {
            if (D === -1 && (D = _), _ - D + 1 === F) return D * u;
          } else
            D !== -1 && (_ -= _ - D), D = -1;
      } else
        for (e + F > a && (e = a - F), _ = e; _ >= 0; _--) {
          for (var I = !0, W = 0; W < F; W++)
            if (T(t, _ + W) !== T(r, W)) {
              I = !1;
              break;
            }
          if (I) return _;
        }
      return -1;
    }
    o.prototype.includes = function(r, e, i) {
      return this.indexOf(r, e, i) !== -1;
    }, o.prototype.indexOf = function(r, e, i) {
      return Z(this, r, e, i, !0);
    }, o.prototype.lastIndexOf = function(r, e, i) {
      return Z(this, r, e, i, !1);
    };
    function wr(t, r, e, i) {
      e = Number(e) || 0;
      var n = t.length - e;
      i ? (i = Number(i), i > n && (i = n)) : i = n;
      var u = r.length;
      i > u / 2 && (i = u / 2);
      for (var a = 0; a < i; ++a) {
        var F = parseInt(r.substr(a * 2, 2), 16);
        if ($(F)) return a;
        t[e + a] = F;
      }
      return a;
    }
    function yr(t, r, e, i) {
      return z(Y(r, t.length - e), t, e, i);
    }
    function mr(t, r, e, i) {
      return z(Ur(r), t, e, i);
    }
    function xr(t, r, e, i) {
      return z(nr(r), t, e, i);
    }
    function Er(t, r, e, i) {
      return z(Ir(r, t.length - e), t, e, i);
    }
    o.prototype.write = function(r, e, i, n) {
      if (e === void 0)
        n = "utf8", i = this.length, e = 0;
      else if (i === void 0 && typeof e == "string")
        n = e, i = this.length, e = 0;
      else if (isFinite(e))
        e = e >>> 0, isFinite(i) ? (i = i >>> 0, n === void 0 && (n = "utf8")) : (n = i, i = void 0);
      else
        throw new Error(
          "Buffer.write(string, encoding, offset[, length]) is no longer supported"
        );
      var u = this.length - e;
      if ((i === void 0 || i > u) && (i = u), r.length > 0 && (i < 0 || e < 0) || e > this.length)
        throw new RangeError("Attempt to write outside buffer bounds");
      n || (n = "utf8");
      for (var a = !1; ; )
        switch (n) {
          case "hex":
            return wr(this, r, e, i);
          case "utf8":
          case "utf-8":
            return yr(this, r, e, i);
          case "ascii":
          case "latin1":
          case "binary":
            return mr(this, r, e, i);
          case "base64":
            return xr(this, r, e, i);
          case "ucs2":
          case "ucs-2":
          case "utf16le":
          case "utf-16le":
            return Er(this, r, e, i);
          default:
            if (a) throw new TypeError("Unknown encoding: " + n);
            n = ("" + n).toLowerCase(), a = !0;
        }
    }, o.prototype.toJSON = function() {
      return {
        type: "Buffer",
        data: Array.prototype.slice.call(this._arr || this, 0)
      };
    };
    function gr(t, r, e) {
      return r === 0 && e === t.length ? p.fromByteArray(t) : p.fromByteArray(t.slice(r, e));
    }
    function j(t, r, e) {
      e = Math.min(t.length, e);
      for (var i = [], n = r; n < e; ) {
        var u = t[n], a = null, F = u > 239 ? 4 : u > 223 ? 3 : u > 191 ? 2 : 1;
        if (n + F <= e) {
          var T, _, D, I;
          switch (F) {
            case 1:
              u < 128 && (a = u);
              break;
            case 2:
              T = t[n + 1], (T & 192) === 128 && (I = (u & 31) << 6 | T & 63, I > 127 && (a = I));
              break;
            case 3:
              T = t[n + 1], _ = t[n + 2], (T & 192) === 128 && (_ & 192) === 128 && (I = (u & 15) << 12 | (T & 63) << 6 | _ & 63, I > 2047 && (I < 55296 || I > 57343) && (a = I));
              break;
            case 4:
              T = t[n + 1], _ = t[n + 2], D = t[n + 3], (T & 192) === 128 && (_ & 192) === 128 && (D & 192) === 128 && (I = (u & 15) << 18 | (T & 63) << 12 | (_ & 63) << 6 | D & 63, I > 65535 && I < 1114112 && (a = I));
          }
        }
        a === null ? (a = 65533, F = 1) : a > 65535 && (a -= 65536, i.push(a >>> 10 & 1023 | 55296), a = 56320 | a & 1023), i.push(a), n += F;
      }
      return dr(i);
    }
    var rr = 4096;
    function dr(t) {
      var r = t.length;
      if (r <= rr)
        return String.fromCharCode.apply(String, t);
      for (var e = "", i = 0; i < r; )
        e += String.fromCharCode.apply(
          String,
          t.slice(i, i += rr)
        );
      return e;
    }
    function Fr(t, r, e) {
      var i = "";
      e = Math.min(t.length, e);
      for (var n = r; n < e; ++n)
        i += String.fromCharCode(t[n] & 127);
      return i;
    }
    function Br(t, r, e) {
      var i = "";
      e = Math.min(t.length, e);
      for (var n = r; n < e; ++n)
        i += String.fromCharCode(t[n]);
      return i;
    }
    function vr(t, r, e) {
      var i = t.length;
      (!r || r < 0) && (r = 0), (!e || e < 0 || e > i) && (e = i);
      for (var n = "", u = r; u < e; ++u)
        n += Sr[t[u]];
      return n;
    }
    function Tr(t, r, e) {
      for (var i = t.slice(r, e), n = "", u = 0; u < i.length - 1; u += 2)
        n += String.fromCharCode(i[u] + i[u + 1] * 256);
      return n;
    }
    o.prototype.slice = function(r, e) {
      var i = this.length;
      r = ~~r, e = e === void 0 ? i : ~~e, r < 0 ? (r += i, r < 0 && (r = 0)) : r > i && (r = i), e < 0 ? (e += i, e < 0 && (e = 0)) : e > i && (e = i), e < r && (e = r);
      var n = this.subarray(r, e);
      return Object.setPrototypeOf(n, o.prototype), n;
    };
    function L(t, r, e) {
      if (t % 1 !== 0 || t < 0) throw new RangeError("offset is not uint");
      if (t + r > e) throw new RangeError("Trying to access beyond buffer length");
    }
    o.prototype.readUintLE = o.prototype.readUIntLE = function(r, e, i) {
      r = r >>> 0, e = e >>> 0, i || L(r, e, this.length);
      for (var n = this[r], u = 1, a = 0; ++a < e && (u *= 256); )
        n += this[r + a] * u;
      return n;
    }, o.prototype.readUintBE = o.prototype.readUIntBE = function(r, e, i) {
      r = r >>> 0, e = e >>> 0, i || L(r, e, this.length);
      for (var n = this[r + --e], u = 1; e > 0 && (u *= 256); )
        n += this[r + --e] * u;
      return n;
    }, o.prototype.readUint8 = o.prototype.readUInt8 = function(r, e) {
      return r = r >>> 0, e || L(r, 1, this.length), this[r];
    }, o.prototype.readUint16LE = o.prototype.readUInt16LE = function(r, e) {
      return r = r >>> 0, e || L(r, 2, this.length), this[r] | this[r + 1] << 8;
    }, o.prototype.readUint16BE = o.prototype.readUInt16BE = function(r, e) {
      return r = r >>> 0, e || L(r, 2, this.length), this[r] << 8 | this[r + 1];
    }, o.prototype.readUint32LE = o.prototype.readUInt32LE = function(r, e) {
      return r = r >>> 0, e || L(r, 4, this.length), (this[r] | this[r + 1] << 8 | this[r + 2] << 16) + this[r + 3] * 16777216;
    }, o.prototype.readUint32BE = o.prototype.readUInt32BE = function(r, e) {
      return r = r >>> 0, e || L(r, 4, this.length), this[r] * 16777216 + (this[r + 1] << 16 | this[r + 2] << 8 | this[r + 3]);
    }, o.prototype.readIntLE = function(r, e, i) {
      r = r >>> 0, e = e >>> 0, i || L(r, e, this.length);
      for (var n = this[r], u = 1, a = 0; ++a < e && (u *= 256); )
        n += this[r + a] * u;
      return u *= 128, n >= u && (n -= Math.pow(2, 8 * e)), n;
    }, o.prototype.readIntBE = function(r, e, i) {
      r = r >>> 0, e = e >>> 0, i || L(r, e, this.length);
      for (var n = e, u = 1, a = this[r + --n]; n > 0 && (u *= 256); )
        a += this[r + --n] * u;
      return u *= 128, a >= u && (a -= Math.pow(2, 8 * e)), a;
    }, o.prototype.readInt8 = function(r, e) {
      return r = r >>> 0, e || L(r, 1, this.length), this[r] & 128 ? (255 - this[r] + 1) * -1 : this[r];
    }, o.prototype.readInt16LE = function(r, e) {
      r = r >>> 0, e || L(r, 2, this.length);
      var i = this[r] | this[r + 1] << 8;
      return i & 32768 ? i | 4294901760 : i;
    }, o.prototype.readInt16BE = function(r, e) {
      r = r >>> 0, e || L(r, 2, this.length);
      var i = this[r + 1] | this[r] << 8;
      return i & 32768 ? i | 4294901760 : i;
    }, o.prototype.readInt32LE = function(r, e) {
      return r = r >>> 0, e || L(r, 4, this.length), this[r] | this[r + 1] << 8 | this[r + 2] << 16 | this[r + 3] << 24;
    }, o.prototype.readInt32BE = function(r, e) {
      return r = r >>> 0, e || L(r, 4, this.length), this[r] << 24 | this[r + 1] << 16 | this[r + 2] << 8 | this[r + 3];
    }, o.prototype.readFloatLE = function(r, e) {
      return r = r >>> 0, e || L(r, 4, this.length), B.read(this, r, !0, 23, 4);
    }, o.prototype.readFloatBE = function(r, e) {
      return r = r >>> 0, e || L(r, 4, this.length), B.read(this, r, !1, 23, 4);
    }, o.prototype.readDoubleLE = function(r, e) {
      return r = r >>> 0, e || L(r, 8, this.length), B.read(this, r, !0, 52, 8);
    }, o.prototype.readDoubleBE = function(r, e) {
      return r = r >>> 0, e || L(r, 8, this.length), B.read(this, r, !1, 52, 8);
    };
    function q(t, r, e, i, n, u) {
      if (!o.isBuffer(t)) throw new TypeError('"buffer" argument must be a Buffer instance');
      if (r > n || r < u) throw new RangeError('"value" argument is out of bounds');
      if (e + i > t.length) throw new RangeError("Index out of range");
    }
    o.prototype.writeUintLE = o.prototype.writeUIntLE = function(r, e, i, n) {
      if (r = +r, e = e >>> 0, i = i >>> 0, !n) {
        var u = Math.pow(2, 8 * i) - 1;
        q(this, r, e, i, u, 0);
      }
      var a = 1, F = 0;
      for (this[e] = r & 255; ++F < i && (a *= 256); )
        this[e + F] = r / a & 255;
      return e + i;
    }, o.prototype.writeUintBE = o.prototype.writeUIntBE = function(r, e, i, n) {
      if (r = +r, e = e >>> 0, i = i >>> 0, !n) {
        var u = Math.pow(2, 8 * i) - 1;
        q(this, r, e, i, u, 0);
      }
      var a = i - 1, F = 1;
      for (this[e + a] = r & 255; --a >= 0 && (F *= 256); )
        this[e + a] = r / F & 255;
      return e + i;
    }, o.prototype.writeUint8 = o.prototype.writeUInt8 = function(r, e, i) {
      return r = +r, e = e >>> 0, i || q(this, r, e, 1, 255, 0), this[e] = r & 255, e + 1;
    }, o.prototype.writeUint16LE = o.prototype.writeUInt16LE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || q(this, r, e, 2, 65535, 0), this[e] = r & 255, this[e + 1] = r >>> 8, e + 2;
    }, o.prototype.writeUint16BE = o.prototype.writeUInt16BE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || q(this, r, e, 2, 65535, 0), this[e] = r >>> 8, this[e + 1] = r & 255, e + 2;
    }, o.prototype.writeUint32LE = o.prototype.writeUInt32LE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || q(this, r, e, 4, 4294967295, 0), this[e + 3] = r >>> 24, this[e + 2] = r >>> 16, this[e + 1] = r >>> 8, this[e] = r & 255, e + 4;
    }, o.prototype.writeUint32BE = o.prototype.writeUInt32BE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || q(this, r, e, 4, 4294967295, 0), this[e] = r >>> 24, this[e + 1] = r >>> 16, this[e + 2] = r >>> 8, this[e + 3] = r & 255, e + 4;
    }, o.prototype.writeIntLE = function(r, e, i, n) {
      if (r = +r, e = e >>> 0, !n) {
        var u = Math.pow(2, 8 * i - 1);
        q(this, r, e, i, u - 1, -u);
      }
      var a = 0, F = 1, T = 0;
      for (this[e] = r & 255; ++a < i && (F *= 256); )
        r < 0 && T === 0 && this[e + a - 1] !== 0 && (T = 1), this[e + a] = (r / F >> 0) - T & 255;
      return e + i;
    }, o.prototype.writeIntBE = function(r, e, i, n) {
      if (r = +r, e = e >>> 0, !n) {
        var u = Math.pow(2, 8 * i - 1);
        q(this, r, e, i, u - 1, -u);
      }
      var a = i - 1, F = 1, T = 0;
      for (this[e + a] = r & 255; --a >= 0 && (F *= 256); )
        r < 0 && T === 0 && this[e + a + 1] !== 0 && (T = 1), this[e + a] = (r / F >> 0) - T & 255;
      return e + i;
    }, o.prototype.writeInt8 = function(r, e, i) {
      return r = +r, e = e >>> 0, i || q(this, r, e, 1, 127, -128), r < 0 && (r = 255 + r + 1), this[e] = r & 255, e + 1;
    }, o.prototype.writeInt16LE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || q(this, r, e, 2, 32767, -32768), this[e] = r & 255, this[e + 1] = r >>> 8, e + 2;
    }, o.prototype.writeInt16BE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || q(this, r, e, 2, 32767, -32768), this[e] = r >>> 8, this[e + 1] = r & 255, e + 2;
    }, o.prototype.writeInt32LE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || q(this, r, e, 4, 2147483647, -2147483648), this[e] = r & 255, this[e + 1] = r >>> 8, this[e + 2] = r >>> 16, this[e + 3] = r >>> 24, e + 4;
    }, o.prototype.writeInt32BE = function(r, e, i) {
      return r = +r, e = e >>> 0, i || q(this, r, e, 4, 2147483647, -2147483648), r < 0 && (r = 4294967295 + r + 1), this[e] = r >>> 24, this[e + 1] = r >>> 16, this[e + 2] = r >>> 8, this[e + 3] = r & 255, e + 4;
    };
    function er(t, r, e, i, n, u) {
      if (e + i > t.length) throw new RangeError("Index out of range");
      if (e < 0) throw new RangeError("Index out of range");
    }
    function tr(t, r, e, i, n) {
      return r = +r, e = e >>> 0, n || er(t, r, e, 4), B.write(t, r, e, i, 23, 4), e + 4;
    }
    o.prototype.writeFloatLE = function(r, e, i) {
      return tr(this, r, e, !0, i);
    }, o.prototype.writeFloatBE = function(r, e, i) {
      return tr(this, r, e, !1, i);
    };
    function ir(t, r, e, i, n) {
      return r = +r, e = e >>> 0, n || er(t, r, e, 8), B.write(t, r, e, i, 52, 8), e + 8;
    }
    o.prototype.writeDoubleLE = function(r, e, i) {
      return ir(this, r, e, !0, i);
    }, o.prototype.writeDoubleBE = function(r, e, i) {
      return ir(this, r, e, !1, i);
    }, o.prototype.copy = function(r, e, i, n) {
      if (!o.isBuffer(r)) throw new TypeError("argument should be a Buffer");
      if (i || (i = 0), !n && n !== 0 && (n = this.length), e >= r.length && (e = r.length), e || (e = 0), n > 0 && n < i && (n = i), n === i || r.length === 0 || this.length === 0) return 0;
      if (e < 0)
        throw new RangeError("targetStart out of bounds");
      if (i < 0 || i >= this.length) throw new RangeError("Index out of range");
      if (n < 0) throw new RangeError("sourceEnd out of bounds");
      n > this.length && (n = this.length), r.length - e < n - i && (n = r.length - e + i);
      var u = n - i;
      return this === r && typeof Uint8Array.prototype.copyWithin == "function" ? this.copyWithin(e, i, n) : Uint8Array.prototype.set.call(
        r,
        this.subarray(i, n),
        e
      ), u;
    }, o.prototype.fill = function(r, e, i, n) {
      if (typeof r == "string") {
        if (typeof e == "string" ? (n = e, e = 0, i = this.length) : typeof i == "string" && (n = i, i = this.length), n !== void 0 && typeof n != "string")
          throw new TypeError("encoding must be a string");
        if (typeof n == "string" && !o.isEncoding(n))
          throw new TypeError("Unknown encoding: " + n);
        if (r.length === 1) {
          var u = r.charCodeAt(0);
          (n === "utf8" && u < 128 || n === "latin1") && (r = u);
        }
      } else typeof r == "number" ? r = r & 255 : typeof r == "boolean" && (r = Number(r));
      if (e < 0 || this.length < e || this.length < i)
        throw new RangeError("Out of range index");
      if (i <= e)
        return this;
      e = e >>> 0, i = i === void 0 ? this.length : i >>> 0, r || (r = 0);
      var a;
      if (typeof r == "number")
        for (a = e; a < i; ++a)
          this[a] = r;
      else {
        var F = o.isBuffer(r) ? r : o.from(r, n), T = F.length;
        if (T === 0)
          throw new TypeError('The value "' + r + '" is invalid for argument "value"');
        for (a = 0; a < i - e; ++a)
          this[a + e] = F[a % T];
      }
      return this;
    };
    var Ar = /[^+/0-9A-Za-z-_]/g;
    function _r(t) {
      if (t = t.split("=")[0], t = t.trim().replace(Ar, ""), t.length < 2) return "";
      for (; t.length % 4 !== 0; )
        t = t + "=";
      return t;
    }
    function Y(t, r) {
      r = r || 1 / 0;
      for (var e, i = t.length, n = null, u = [], a = 0; a < i; ++a) {
        if (e = t.charCodeAt(a), e > 55295 && e < 57344) {
          if (!n) {
            if (e > 56319) {
              (r -= 3) > -1 && u.push(239, 191, 189);
              continue;
            } else if (a + 1 === i) {
              (r -= 3) > -1 && u.push(239, 191, 189);
              continue;
            }
            n = e;
            continue;
          }
          if (e < 56320) {
            (r -= 3) > -1 && u.push(239, 191, 189), n = e;
            continue;
          }
          e = (n - 55296 << 10 | e - 56320) + 65536;
        } else n && (r -= 3) > -1 && u.push(239, 191, 189);
        if (n = null, e < 128) {
          if ((r -= 1) < 0) break;
          u.push(e);
        } else if (e < 2048) {
          if ((r -= 2) < 0) break;
          u.push(
            e >> 6 | 192,
            e & 63 | 128
          );
        } else if (e < 65536) {
          if ((r -= 3) < 0) break;
          u.push(
            e >> 12 | 224,
            e >> 6 & 63 | 128,
            e & 63 | 128
          );
        } else if (e < 1114112) {
          if ((r -= 4) < 0) break;
          u.push(
            e >> 18 | 240,
            e >> 12 & 63 | 128,
            e >> 6 & 63 | 128,
            e & 63 | 128
          );
        } else
          throw new Error("Invalid code point");
      }
      return u;
    }
    function Ur(t) {
      for (var r = [], e = 0; e < t.length; ++e)
        r.push(t.charCodeAt(e) & 255);
      return r;
    }
    function Ir(t, r) {
      for (var e, i, n, u = [], a = 0; a < t.length && !((r -= 2) < 0); ++a)
        e = t.charCodeAt(a), i = e >> 8, n = e % 256, u.push(n), u.push(i);
      return u;
    }
    function nr(t) {
      return p.toByteArray(_r(t));
    }
    function z(t, r, e, i) {
      for (var n = 0; n < i && !(n + e >= r.length || n >= t.length); ++n)
        r[n + e] = t[n];
      return n;
    }
    function M(t, r) {
      return t instanceof r || t != null && t.constructor != null && t.constructor.name != null && t.constructor.name === r.name;
    }
    function $(t) {
      return t !== t;
    }
    var Sr = function() {
      for (var t = "0123456789abcdef", r = new Array(256), e = 0; e < 16; ++e)
        for (var i = e * 16, n = 0; n < 16; ++n)
          r[i + n] = t[e] + t[n];
      return r;
    }();
  }(V)), V;
}
var P = { exports: {} }, pr;
function zr() {
  return pr || (pr = 1, function(c, p) {
    var B = function() {
      if (typeof self < "u")
        return self;
      if (typeof window < "u")
        return window;
      if (typeof ar < "u")
        return ar;
      throw new Error("unable to locate global object");
    }, g = B();
    c.exports = p = g.fetch, g.fetch && (p.default = g.fetch.bind(g)), p.Headers = g.Headers, p.Request = g.Request, p.Response = g.Response;
  }(P, P.exports)), P.exports;
}
var O = { exports: {} }, cr;
function Wr() {
  if (cr) return O.exports;
  cr = 1;
  const { AbortController: c, AbortSignal: p } = typeof self < "u" ? self : typeof window < "u" ? window : (
    /* otherwise */
    void 0
  );
  return O.exports = c, O.exports.AbortSignal = p, O.exports.default = c, O.exports;
}
let Hr = kr(
  typeof Blob < "u" ? Blob : Or().Blob,
  typeof fetch < "u" ? fetch : zr(),
  typeof AbortController < "u" ? AbortController : Wr()
);
var Pr = { Logger: Hr };
const Jr = /* @__PURE__ */ Mr(Pr), N = console.error, J = console.warn, K = console.info, lr = Cr({
  // eslint-disable-next-line complexity
  log: (c, p, B) => {
    const g = B ? Object.keys(B).reduce((d, x) => d.replace(new RegExp(`{${x}}`, "g"), JSON.stringify(B[x]))) : p;
    switch (c) {
      case "Verbose":
      case "Debug":
      case "Information":
        K(g);
        break;
      case "Warning":
        J(g);
        break;
      case "Error":
      case "Fatal":
        N(g);
        break;
    }
  }
}), Xr = (c) => {
  const {
    serverUrl: p = "https://seq.oncoreflex.dev",
    apiKey: B,
    tags: g,
    redirectError: d = !1,
    redirectWarn: x = !1,
    redirectInfo: y = !1,
    children: o
  } = c, s = Rr(() => new Jr.Logger({
    serverUrl: p,
    apiKey: B,
    onError: (h) => N(h)
  }), [p, B]);
  Lr(() => () => {
    s.flush().then();
  });
  const m = (h, l, v) => {
    try {
      s.emit({
        level: h,
        timestamp: /* @__PURE__ */ new Date(),
        messageTemplate: l,
        properties: {
          ...g,
          ...v
        }
      });
    } catch (f) {
      N(f);
    }
  };
  return console.error = d ? function(...h) {
    try {
      h.forEach((l) => m("Error", l));
    } catch (l) {
      N(l);
    }
    N(...h);
  } : N, console.warn = x ? function(...h) {
    try {
      h.forEach((l) => m("Warning", l));
    } catch (l) {
      J(l);
    }
    J(...h);
  } : J, console.info = y ? function(...h) {
    try {
      h.forEach((l) => m("Information", l));
    } catch (l) {
      K(l);
    }
    K(...h);
  } : K, /* @__PURE__ */ qr.jsx(lr.Provider, { value: {
    log: (h, l, v) => m(h, l, v)
  }, children: o });
}, Vr = () => Dr(lr);
export {
  Xr as L,
  Vr as u
};
