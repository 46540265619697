import { j as e } from "./jsx-runtime-DmTjM30f.js";
import { forwardRef as f } from "react";
import l from "styled-components";
import "./components/Theme/themes.es.js";
import "./palettes-Dfhobg_2.js";
import { getRadii as $, getPaletteForeground as u } from "./components/Theme/utils.es.js";
import "./CssBaseline-E-zwSHrJ.js";
import { sizeStyles as x, variantStyles as y, iconSizeStyles as h } from "./components/Button/styles.es.js";
import { t as g } from "./styles-CuuuEl0j.js";
import { T as z } from "./Typography-DPoSDQ1T.js";
const S = {
  xs: "xs",
  sm: "sm",
  md: "sm",
  lg: "md",
  xl: "md",
  "2xl": "lg"
}, j = l.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-radius: ${$("full")};

  ${(t) => x[t.$size]};
  ${(t) => y[t.$variant]};
  
  ${(t) => g.variants.text[S[t.$size]]}

  &:disabled {
    cursor: not-allowed;

    color: ${u("disabled.default")};
  }
`, s = l.div`
  display: inline-flex;

  ${(t) => h[t.$size]}

  > svg {
    width: inherit;
    height: inherit;
  }
`, _ = f(
  function(d, a) {
    const {
      color: m = "brand",
      size: o = "md",
      variant: c = "contained",
      children: r,
      startAdornment: i,
      endAdornment: n,
      ...p
    } = d;
    return /* @__PURE__ */ e.jsxs(
      j,
      {
        ref: a,
        $color: m,
        $size: o,
        $variant: c,
        ...p,
        children: [
          i && /* @__PURE__ */ e.jsx(s, { $size: o, children: i }),
          typeof r == "string" ? /* @__PURE__ */ e.jsx(
            z,
            {
              color: "none",
              weight: "semibold",
              size: "none",
              children: r
            }
          ) : r,
          n && /* @__PURE__ */ e.jsx(s, { $size: o, children: n })
        ]
      }
    );
  }
);
export {
  _ as B
};
