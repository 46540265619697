import { i as e } from "./polished.esm-B6VUs5va.js";
import r, { invertColors as d, invertColor as y } from "./components/Theme/colors.es.js";
const a = {
  base: {
    white: "#ffffff",
    black: "#000000",
    transparent: "transparent"
  },
  text: {
    default: "#000000",
    primary: {
      default: r.gray[900],
      brand: "#ffffff"
    },
    secondary: {
      default: r.gray[700],
      hover: r.gray[800],
      brand: r.brand[200]
    },
    tertiary: {
      default: r.gray[600],
      hover: r.gray[700],
      brand: r.brand[200]
    },
    quarterary: {
      default: r.gray[500],
      brand: r.brand[300]
    },
    white: "#ffffff",
    disabled: r.gray[500],
    placeholder: r.gray[500],
    placeholderSubtle: r.gray[300],
    brand: {
      primary: r.brand[900],
      secondary: r.brand[700],
      tertiary: r.brand[600],
      tertiaryAlt: r.brand[600]
    },
    error: r.error[600],
    warning: r.warning[600],
    success: r.success[600]
  },
  background: {
    default: "#ffffff",
    primary: {
      default: "#ffffff",
      alt: "#ffffff",
      hover: r.gray[50],
      solid: r.gray[950]
    },
    secondary: {
      default: r.gray[50],
      alt: r.gray[50],
      hover: r.gray[100],
      subtle: r.gray[600],
      solid: r.gray[50]
    },
    tertiary: r.gray[100],
    quarterary: r.gray[200],
    active: r.gray[50],
    disabled: {
      default: r.gray[100],
      subtle: r.gray[50]
    },
    overlay: r.gray[950],
    brand: {
      primary: {
        default: r.brand[50],
        alt: r.brand[50]
      },
      secondary: {
        default: r.brand[100]
      },
      solid: {
        default: r.brand[600],
        hover: r.brand[700]
      },
      section: {
        default: r.brand[800],
        subtle: r.brand[700]
      }
    },
    error: {
      primary: r.error[50],
      secondary: r.error[100],
      solid: r.error[600]
    },
    warning: {
      primary: r.warning[50],
      secondary: r.warning[100],
      solid: r.warning[600]
    },
    success: {
      primary: r.success[50],
      secondary: r.success[100],
      solid: r.success[600]
    }
  },
  foreground: {
    primary: {
      default: r.gray[900]
    },
    secondary: {
      default: r.gray[700],
      hover: r.gray[800]
    },
    tertiary: {
      default: r.gray[600],
      hover: r.gray[700]
    },
    quarterary: {
      default: r.gray[500],
      hover: r.gray[600]
    },
    quinary: {
      default: r.gray[400],
      hover: r.gray[500]
    },
    senary: {
      default: r.gray[300]
    },
    septenary: {
      default: r.gray[200]
    },
    white: "#ffffff",
    disabled: {
      default: r.gray[400],
      subtle: r.gray[300]
    },
    brand: {
      primary: {
        default: r.brand[600],
        alt: r.brand[600]
      },
      secondary: {
        default: r.brand[500]
      }
    },
    error: {
      primary: r.error[600],
      secondary: r.error[500]
    },
    warning: {
      primary: r.warning[600],
      secondary: r.warning[500]
    },
    success: {
      primary: r.success[600],
      secondary: r.success[500]
    }
  },
  border: {
    primary: r.gray[300],
    secondary: r.gray[200],
    tertiary: r.gray[100],
    disabled: {
      default: r.gray[300],
      subtle: r.gray[200]
    },
    brand: {
      default: r.brand[300],
      solid: {
        default: r.brand[600],
        alt: r.brand[600]
      }
    },
    error: {
      default: r.error[300],
      solid: r.error[600]
    }
  },
  colors: {
    ...r,
    brandAlt: r.brand
  }
}, t = {
  ...a,
  text: {
    default: e(a.text.default),
    primary: {
      default: r.gray[50],
      brand: r.gray[50]
    },
    secondary: {
      default: r.gray[300],
      hover: r.gray[200],
      brand: r.brand[300]
    },
    tertiary: {
      default: r.gray[400],
      hover: r.gray[300],
      brand: r.brand[400]
    },
    quarterary: {
      default: r.gray[400],
      brand: r.gray[400]
    },
    white: "#ffffff",
    disabled: r.gray[500],
    placeholder: r.gray[400],
    placeholderSubtle: r.gray[700],
    brand: {
      primary: r.gray[50],
      secondary: r.gray[300],
      tertiary: r.gray[400],
      tertiaryAlt: r.gray[50]
    },
    error: r.error[400],
    warning: r.warning[400],
    success: r.success[400]
  },
  background: {
    default: e(a.background.default),
    primary: {
      default: r.gray[950],
      alt: r.gray[900],
      hover: r.gray[800],
      solid: r.gray[900]
    },
    secondary: {
      default: r.gray[900],
      alt: r.gray[950],
      hover: r.gray[800],
      subtle: r.gray[900],
      solid: r.gray[600]
    },
    tertiary: r.gray[800],
    quarterary: r.gray[700],
    active: r.gray[800],
    disabled: {
      default: r.gray[800],
      subtle: r.gray[900]
    },
    overlay: r.gray[800],
    brand: {
      primary: {
        default: r.brand[500],
        alt: r.gray[800]
      },
      secondary: {
        default: r.brand[600]
      },
      solid: {
        default: r.brand[600],
        hover: r.brand[500]
      },
      section: {
        default: r.gray[800],
        subtle: r.gray[950]
      }
    },
    error: {
      primary: r.error[500],
      secondary: r.error[600],
      solid: r.error[600]
    },
    warning: {
      primary: r.warning[500],
      secondary: r.warning[600],
      solid: r.warning[600]
    },
    success: {
      primary: r.success[500],
      secondary: r.success[600],
      solid: r.success[600]
    }
  },
  foreground: {
    primary: {
      default: "#ffffff"
    },
    secondary: {
      default: r.gray[300],
      hover: r.gray[200]
    },
    tertiary: {
      default: r.gray[400],
      hover: r.gray[300]
    },
    quarterary: {
      default: r.gray[400],
      hover: r.gray[300]
    },
    quinary: {
      default: r.gray[500],
      hover: r.gray[400]
    },
    senary: {
      default: r.gray[600]
    },
    septenary: {
      default: r.gray[700]
    },
    white: "#ffffff",
    disabled: {
      default: r.gray[500],
      subtle: r.gray[600]
    },
    brand: {
      primary: {
        default: r.brand[500],
        alt: r.gray[300]
      },
      secondary: {
        default: r.brand[500]
      }
    },
    error: {
      primary: r.error[500],
      secondary: r.error[400]
    },
    warning: {
      primary: r.warning[500],
      secondary: r.warning[400]
    },
    success: {
      primary: r.success[500],
      secondary: r.success[400]
    }
  },
  border: {
    primary: r.gray[700],
    secondary: r.gray[800],
    tertiary: r.gray[800],
    disabled: {
      default: r.gray[700],
      subtle: r.gray[800]
    },
    brand: {
      default: r.brand[400],
      solid: {
        default: r.brand[600],
        alt: r.brand[700]
      }
    },
    error: {
      default: r.error[400],
      solid: r.error[600]
    }
  },
  colors: {
    ...d(a.colors),
    brandAlt: y(r.gray)
  }
}, l = /* @__PURE__ */ Object.freeze(/* @__PURE__ */ Object.defineProperty({
  __proto__: null,
  darkPalette: t,
  default: a,
  lightPalette: a
}, Symbol.toStringTag, { value: "Module" }));
export {
  t as d,
  a as l,
  l as p
};
