import { j as r } from "./jsx-runtime-DmTjM30f.js";
import { useMemo as e, Suspense as m } from "react";
import i from "./components/Icon/variants.es.js";
const x = (s) => {
  const {
    variant: o,
    ...t
  } = s, n = e(() => i[o], [o]);
  return /* @__PURE__ */ r.jsx(m, { children: /* @__PURE__ */ r.jsx(n, { ...t }) });
};
export {
  x as I
};
