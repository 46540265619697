import { useEffect as I, createContext as A, useState as T, useMemo as w, useContext as E } from "react";
import { j as t } from "./jsx-runtime-DmTjM30f.js";
import r, { keyframes as P } from "styled-components";
import "./components/Theme/themes.es.js";
import "./palettes-Dfhobg_2.js";
import { getSpacings as o, getPaletteBorder as k, getRadii as D, getPaletteBackground as _, getEffects as z, getBreakpointMediaQuery as u, getZIndexes as S } from "./components/Theme/utils.es.js";
import "./CssBaseline-E-zwSHrJ.js";
import { I as B } from "./IconButton-BUDydTIR.js";
import { I as M } from "./Icon-BJLMcRlb.js";
import "./components/Icon/countryVariants.es.js";
import { F as R } from "./FileIcon-BcenXPq_.js";
import "./components/FeaturedIcon/constants.es.js";
import "./styles-CuuuEl0j.js";
import { T as b } from "./Typography-DPoSDQ1T.js";
import { notificationItemAnimationKeyframes as q, notificationListStyles as F } from "./components/Notification/styles.es.js";
import { STATE_VARIANT_MAPPER as L } from "./components/Notification/constants.es.js";
const O = r.div`
  display: flex;
  position: relative;
  width: 400px;
  box-sizing: border-box;
  
  animation-timing-function: ease-in-out;
  animation-duration: 300ms;
  
  animation-name: ${(i) => q[i.$position]};
  
  padding: ${o("xl")};
  border: 1px solid ${k("primary")};
  border-radius: ${D("xl")};
  background: ${_("primary.default")};
  box-shadow: ${z("shadows.lg")};
  
  ${u("max", "lg")} {
    width: 100%;
  }
`, U = r.div`
  display: flex;
  flex-direction: row;
  
  padding-right: ${o("4xl")};
  gap: ${o("xl")};
  
  ${u("max", "lg")} {
    flex-direction: column;
  }
`, H = r.div`
  display: flex;
  flex-direction: column;
  
  gap: ${o("lg")};
`, K = r.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  
  gap: ${o("xs")};
`, Q = r(B)`
  position: absolute;
  top: 8px;
  right: 8px;
`, V = (i) => {
  const {
    title: l,
    message: a,
    variant: e,
    className: f,
    position: d = "bottom-left",
    children: m,
    autoCloseDuration: s,
    onClose: c
  } = i;
  return I(() => {
    let p;
    return c && s && (p = setTimeout(() => {
      c();
    }, s)), () => {
      clearTimeout(p);
    };
  }, []), /* @__PURE__ */ t.jsxs(
    O,
    {
      $position: d,
      className: f,
      children: [
        /* @__PURE__ */ t.jsx(
          Q,
          {
            variant: "ghost",
            color: "gray",
            onClick: c,
            children: /* @__PURE__ */ t.jsx(
              M,
              {
                variant: "close"
              }
            )
          }
        ),
        /* @__PURE__ */ t.jsxs(U, { children: [
          e && /* @__PURE__ */ t.jsx(
            R,
            {
              size: "sm",
              variant: "outlined",
              color: e,
              iconProps: {
                variant: L[e]
              }
            }
          ),
          /* @__PURE__ */ t.jsxs(H, { children: [
            /* @__PURE__ */ t.jsxs(K, { children: [
              /* @__PURE__ */ t.jsx(
                b,
                {
                  size: "sm",
                  weight: "semibold",
                  children: l
                }
              ),
              a && /* @__PURE__ */ t.jsx(
                b,
                {
                  size: "sm",
                  weight: "regular",
                  children: a
                }
              )
            ] }),
            m
          ] })
        ] })
      ]
    }
  );
}, Z = P`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`, G = r.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  position: fixed;
  background: linear-gradient(314deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.00)47.44%);
  
  animation: ease-in-out ${Z} 150ms;
  
  gap: ${o("3xl")};
  padding-top: ${o("3xl")};
  padding-bottom: ${o("3xl")};
  z-index: ${S("notification")};
  
  ${u("max", "lg")} {
    width: 100%;
    background: linear-gradient(357deg, rgba(0, 0, 0, 0.10) 2.75%, rgba(0, 0, 0, 0.00) 92.77%);
    
    gap: ${o("xl")};
    padding-top: ${o("xl")};
    padding-bottom: ${o("xl")};
    
    padding-left: ${o("md")};
    padding-right: ${o("md")};
  };
  
  ${(i) => F[i.$position]};
`, J = (i) => {
  const {
    children: l,
    className: a,
    position: e = "bottom-left"
  } = i;
  return /* @__PURE__ */ t.jsx(
    G,
    {
      $position: e,
      className: a,
      children: l
    }
  );
}, N = A({
  enqueueNotification: () => {
  },
  removeAllNotifications: () => {
  }
}), W = 8e3, xt = (i) => {
  const {
    children: l,
    className: a,
    position: e = "bottom-left",
    autoHideDuration: f = W
  } = i, [
    d,
    m
  ] = T({}), s = w(() => Object.values(d), [d]), c = (n) => {
    const {
      onClose: h,
      ...y
    } = n, x = Date.now(), v = (g) => {
      m((C) => {
        const $ = {
          ...C
        };
        return delete $[g], $;
      });
    }, j = () => {
      h && h(), v(x);
    };
    m((g) => ({
      ...g,
      [x]: {
        autoCloseDuration: f,
        ...y,
        id: x,
        onClose: j
      }
    }));
  }, p = () => {
    s.forEach(({ onClose: n }) => {
      n && n();
    });
  };
  return /* @__PURE__ */ t.jsxs(
    N.Provider,
    {
      value: {
        enqueueNotification: c,
        removeAllNotifications: p
      },
      children: [
        l,
        s.length > 0 && /* @__PURE__ */ t.jsx(
          J,
          {
            className: a,
            position: e,
            children: s.map((n) => /* @__PURE__ */ t.jsx(
              V,
              {
                ...n,
                position: e
              },
              n.id
            ))
          }
        )
      ]
    }
  );
}, gt = () => E(N);
export {
  xt as N,
  gt as u
};
