import { j as n } from "./jsx-runtime-DmTjM30f.js";
import s from "styled-components";
import "./components/Theme/themes.es.js";
import "./palettes-Dfhobg_2.js";
import { getRadii as a, getPaletteForeground as l } from "./components/Theme/utils.es.js";
import "react";
import "./CssBaseline-E-zwSHrJ.js";
import { variantStyles as d, sizeStyles as m } from "./components/IconButton/styles.es.js";
const c = s.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  border-radius: ${a("full")};
  
  ${(t) => d[t.$variant]}
  ${(t) => m[t.$size]}
  
  &:disabled {
    cursor: not-allowed;
    
    color: ${l("disabled.default")};
  }
`, j = (t) => {
  const {
    variant: o = "contained",
    color: r = "brand",
    size: e = "md",
    ...i
  } = t;
  return /* @__PURE__ */ n.jsx(
    c,
    {
      $variant: o,
      $color: r,
      $size: e,
      ...i
    }
  );
};
export {
  j as I
};
