import { css as r } from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-Dfhobg_2.js";
import { getSpacings as o, getPaletteForeground as e, getPaletteBackground as t, getPaletteText as a } from "../Theme/utils.es.js";
import "react";
import "../../jsx-runtime-DmTjM30f.js";
import "../../CssBaseline-E-zwSHrJ.js";
const g = {
  root: r`
    display: flex;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    gap: 8px;

    padding: ${o("md")} ${o("lg")};
    cursor: pointer;

    border: none;
    border-radius: 6px;

    color: ${e("quarterary.default")};
    background-color: ${t("primary.default")};

    text-decoration: none;

    span {
      color: ${a("secondary.default")};
    }
  `,
  active: r`
    color: ${e("quarterary.hover")};
    background-color: ${t("primary.hover")};

    span {
      color: ${a("secondary.hover")};
    }
  `
};
export {
  g as sideBarItemStyles
};
