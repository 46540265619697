import { j as e } from "./jsx-runtime-DmTjM30f.js";
import m from "styled-components";
import { c as b } from "./clsx.m-BMVuQnLY.js";
import { i as z } from "./isExhaustive-RFXKPwPh.js";
import { A as U } from "./AvatarLabelGroup-C4zzKo4w.js";
import "./styles-CuuuEl0j.js";
import { T as g } from "./Typography-DPoSDQ1T.js";
import { getPaletteForeground as k, getSpacings as j, getRadii as V, getPaletteBackground as y, getPaletteBorder as E, getPaletteBase as D } from "./components/Theme/utils.es.js";
import { createPortal as A } from "react-dom";
import { Menu as x } from "@headlessui/react";
import { A as X } from "./Avatar-B5kFEaeY.js";
import "./components/Theme/themes.es.js";
import "./palettes-Dfhobg_2.js";
import { useRef as Y, useMemo as Z } from "react";
import "./CssBaseline-E-zwSHrJ.js";
import { I as _ } from "./Icon-BJLMcRlb.js";
import "./components/Icon/countryVariants.es.js";
import { Items as M } from "./components/Dropdown/style.es.js";
import { B as ee } from "./Button-iQ12_5TW.js";
import { u as te } from "./useDisclose-DpzoV9h_.js";
import { u as T } from "./useAppFloating-CHeVJssO.js";
const ne = m.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: 100%;
`, se = m.div`
  flex: 1;
  text-align: left;
  min-width: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`, F = m(_)`
  color: ${k("quarterary.default")};
`, W = (n) => {
  const {
    text: s,
    shortcut: t,
    leftIcon: r,
    rightIcon: o,
    className: c
  } = n, i = {
    width: 16,
    height: 16
  };
  return /* @__PURE__ */ e.jsxs(ne, { className: c, children: [
    r && /* @__PURE__ */ e.jsx(
      F,
      {
        ...i,
        ...r
      }
    ),
    /* @__PURE__ */ e.jsxs(se, { children: [
      /* @__PURE__ */ e.jsx(g, { variant: "text", size: "sm", weight: "medium", as: "span", className: "text", children: s }),
      t && /* @__PURE__ */ e.jsx(g, { variant: "text", size: "xs", weight: "regular", as: "span", color: "tertiary", children: t })
    ] }),
    o && /* @__PURE__ */ e.jsx(
      F,
      {
        ...i,
        ...o
      }
    )
  ] });
}, re = m.button`
  margin: ${j("xxs")} ${j("sm")};

  flex: 1;

  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: ${V("sm")};

  padding: 9px 10px;

  border: none;

  color: ${k("quarterary.default")};

  background-color: ${y("primary.default")};

  overflow: hidden;

  outline: none;

  &:not(.disabled):hover {
    cursor: pointer;
    background-color: ${y("primary.hover")};
  }
`, P = (n) => {
  const {
    selected: s,
    disabled: t,
    onClick: r,
    className: o,
    ...c
  } = n, i = b(
    {
      selected: s,
      disabled: t
    },
    o
  );
  return /* @__PURE__ */ e.jsx(
    x.Item,
    {
      as: re,
      disabled: t,
      className: i,
      onClick: r,
      children: /* @__PURE__ */ e.jsx(W, { ...c })
    }
  );
}, oe = m.div`
  z-index: 10;
  
  display: flex;
  flex-direction: column;
  
  padding: 4px 0;
    
  &:not(:first-child) {
    border-top: 1px solid ${E("secondary")};
  }
`, ie = (n) => {
  const {
    className: s,
    children: t,
    ...r
  } = n;
  return /* @__PURE__ */ e.jsx(oe, { ...r, className: s, children: t });
}, O = m.header`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  
  padding: 12px 16px;

  border-bottom: 1px solid ${E("secondary")};
`, Pe = (n) => {
  switch (n.variant) {
    case "avatar": {
      const {
        variant: s,
        avatar: {
          size: t,
          ...r
        },
        className: o,
        ...c
      } = n;
      return /* @__PURE__ */ e.jsx(O, { ...c, className: b(`variant-${s}`, o), children: /* @__PURE__ */ e.jsx(U, { ...r, size: t ?? "md" }) });
    }
    case "text": {
      const {
        variant: s,
        text: t,
        className: r,
        ...o
      } = n;
      return /* @__PURE__ */ e.jsx(O, { ...o, className: b(`variant-${s}`, r), children: /* @__PURE__ */ e.jsx(g, { variant: "text", size: "sm", weight: "semibold", as: "span", children: t }) });
    }
    default:
      z(n);
  }
}, ae = (n) => {
  const {
    currentValue: s,
    selectedValue: t,
    isMultiple: r
  } = n;
  if (!r)
    return t;
  const o = s.split(",").filter((i) => !!i);
  return o.find((i) => i === t) ? [
    ...o
  ].filter((i) => i !== t).join(",") : [
    ...o,
    t
  ].join(",");
}, le = m(ie)`
  &:not(:first-child) {
    border: none;
  }
  .selected {
    svg {
      color: ${k("brand.primary.default")};
    }
  }
`, ce = m.input`
  display: none;
`, de = (n) => {
  const {
    label: s,
    value: t,
    options: r,
    className: o,
    placement: c,
    renderOption: i,
    onChange: h,
    inputRef: f,
    isMultiple: p,
    ...w
  } = n, {
    isOpen: $,
    onClose: I,
    onOpen: H
  } = te(!1), {
    refs: S,
    floatingStyles: q
  } = T({
    floatingOptions: {
      placement: c,
      open: !1
    }
  }), v = Y(null), B = Z(() => {
    if (p) {
      const a = [
        ...new Set(t.split(","))
      ];
      if (a.length === 1) {
        const [d] = a, u = r.find((N) => N.value === d);
        return u ? `: ${u.label}` : "";
      }
      if (a.length > 1)
        return ` (${a.length})`;
    }
    const l = r.find((a) => a.value === t);
    return l ? `: ${l.label}` : "";
  }, [
    t,
    r
  ]), L = r.length === 0, G = typeof i < "u", J = (l) => p ? t.split(",").findIndex((d) => d === l) !== -1 : l === t, C = (l, a) => {
    var u;
    const d = (u = Object.getOwnPropertyDescriptor(
      HTMLInputElement.prototype,
      "value"
    )) == null ? void 0 : u.set;
    if (v.current && d) {
      const N = ae({
        currentValue: t,
        selectedValue: a,
        isMultiple: p
      });
      d.call(v.current, N);
      const Q = new Event("input", {
        bubbles: !0
      });
      v.current.dispatchEvent(Q);
    }
    p && l.preventDefault();
  }, K = () => {
    $ ? I() : H();
  };
  return /* @__PURE__ */ e.jsxs(x, { as: le, children: [
    /* @__PURE__ */ e.jsx(
      x.Button,
      {
        as: ee,
        className: o,
        ref: S.setReference,
        onClick: K,
        ...w,
        children: typeof s == "string" ? /* @__PURE__ */ e.jsxs(g, { size: "sm", color: "none", weight: "semibold", children: [
          s,
          B
        ] }) : /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
          s,
          /* @__PURE__ */ e.jsx(g, { size: "sm", color: "none", weight: "semibold", children: B })
        ] })
      }
    ),
    /* @__PURE__ */ e.jsx(
      ce,
      {
        ref: (l) => {
          typeof f == "function" ? f(l) : f && (f.current = l), v.current = l;
        },
        value: t,
        onChange: h
      }
    ),
    A(
      /* @__PURE__ */ e.jsx(M, { style: q, ref: S.setFloating, children: L ? /* @__PURE__ */ e.jsx(P, { disabled: !0, text: "No data" }) : /* @__PURE__ */ e.jsx(e.Fragment, { children: r.map(({ label: l, value: a }) => {
        const d = J(a);
        return G ? /* @__PURE__ */ e.jsx(
          x.Item,
          {
            as: "div",
            onClick: (u) => C(u, a),
            children: i({ label: l, value: a, isSelected: d })
          },
          a
        ) : /* @__PURE__ */ e.jsx(
          P,
          {
            text: l,
            selected: d,
            onClick: (u) => C(u, a),
            rightIcon: d ? { variant: "check", color: "red" } : void 0
          },
          a
        );
      }) }) }),
      document.body
    )
  ] });
}, R = m(x.Button)`
  &:not(.disabled) {
    cursor: pointer;
  }

  padding: 0;
  border-style: none;
  background: ${y("primary.default")};

  &.variant-avatar {
    color: ${D("black")};

    &:hover {
      color: ${D("black")};
    }

    outline: none;
  }

  &.variant-select {
    padding: ${j("md")} ${j("lg")};
    border-radius: ${V("sm")};

    &:hover {
      background: ${y("primary.hover")};
    }
  }

  width: ${(n) => n.$isFullWidth ? "100%" : ""};
`, Oe = (n) => {
  const {
    variant: s,
    disabled: t,
    children: r,
    className: o,
    fullWidth: c,
    placement: i = "bottom-end"
  } = n, {
    refs: h,
    floatingStyles: f
  } = T({
    floatingOptions: {
      placement: i
    }
  }), p = b(`variant-${s}`, o), w = () => {
    switch (s) {
      case "avatar": {
        const { size: $, ...I } = n.avatarProps;
        return /* @__PURE__ */ e.jsx(
          R,
          {
            ref: h.setReference,
            className: p,
            disabled: t,
            children: /* @__PURE__ */ e.jsx(X, { size: $ ?? "md", ...I })
          }
        );
      }
      case "select":
        return /* @__PURE__ */ e.jsx(
          R,
          {
            className: p,
            ref: h.setReference,
            $isFullWidth: c,
            disabled: t,
            children: /* @__PURE__ */ e.jsx(W, { ...n.selectProps })
          }
        );
      case "filter":
        return /* @__PURE__ */ e.jsx(de, { placement: i, ...n.filterSelectProps });
      default:
        z(s);
    }
  };
  return /* @__PURE__ */ e.jsxs(x, { children: [
    w(),
    s === "filter" ? void 0 : /* @__PURE__ */ e.jsx(e.Fragment, { children: A(
      /* @__PURE__ */ e.jsx(
        M,
        {
          ref: h.setFloating,
          style: f,
          $isFullWidth: c,
          children: r
        }
      ),
      document.body
    ) })
  ] });
};
export {
  P as D,
  ie as a,
  Pe as b,
  Oe as c,
  de as d
};
