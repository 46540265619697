import { j as e } from "../../jsx-runtime-DmTjM30f.js";
import "../../styles-CuuuEl0j.js";
import { T as r } from "../../Typography-DPoSDQ1T.js";
import { B as l } from "../../Button-iQ12_5TW.js";
import { I as m } from "../../Icon-BJLMcRlb.js";
import "react";
import "../Icon/countryVariants.es.js";
import a from "styled-components";
import "../Theme/themes.es.js";
import "../../palettes-Dfhobg_2.js";
import { getWidths as g, getSpacings as n } from "../Theme/utils.es.js";
import "../../CssBaseline-E-zwSHrJ.js";
import "../../ErrorFeedbackContainer-B4ItEEcS.js";
import { S as d, P as x } from "../../SomethingWentWrongFeedback-Drb_PlvB.js";
const p = a.div`
  display: flex;
  height: ${g("xl")};
  padding: 0 ${n("3xl")};
  flex-direction: column;
  align-items: center;
  align-self: center;

  gap: ${n("4xl")};
`, h = a.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${n("6xl")} 48px;
  flex: 1 0 0;
  align-self: stretch;
`, f = a.div`
  display: flex;
  align-items: flex-start;
  gap: ${n("lg")};
`, c = (o) => {
  const {
    className: i,
    actions: t,
    feedback: s
  } = o;
  return /* @__PURE__ */ e.jsx(p, { className: i, children: /* @__PURE__ */ e.jsxs(h, { children: [
    s,
    /* @__PURE__ */ e.jsx(f, { children: t })
  ] }) });
}, G = (o) => {
  const {
    className: i,
    onGoBackClick: t,
    onGotoLoginClick: s
  } = o;
  return /* @__PURE__ */ e.jsx(
    c,
    {
      className: i,
      feedback: /* @__PURE__ */ e.jsx(
        d,
        {
          message: "The page you are looking for doesn't exist or has been moved."
        }
      ),
      actions: /* @__PURE__ */ e.jsxs(e.Fragment, { children: [
        /* @__PURE__ */ e.jsx(
          l,
          {
            size: "2xl",
            startAdornment: /* @__PURE__ */ e.jsx(m, { variant: "arrow-left" }),
            variant: "outlined",
            color: "gray",
            onClick: t,
            children: /* @__PURE__ */ e.jsx(r, { size: "lg", weight: "semibold", color: "secondary", children: "Go back" })
          }
        ),
        /* @__PURE__ */ e.jsx(
          l,
          {
            size: "2xl",
            onClick: s,
            children: /* @__PURE__ */ e.jsx(r, { size: "lg", weight: "semibold", color: "white", children: "Go to log in" })
          }
        )
      ] })
    }
  );
}, S = (o) => {
  const {
    className: i,
    onRefreshClick: t
  } = o;
  return /* @__PURE__ */ e.jsx(
    c,
    {
      className: i,
      feedback: /* @__PURE__ */ e.jsx(
        x,
        {
          message: "We are working on fixing the problem. Please try again."
        }
      ),
      actions: /* @__PURE__ */ e.jsx(
        l,
        {
          size: "2xl",
          onClick: t,
          children: /* @__PURE__ */ e.jsx(r, { size: "lg", weight: "semibold", color: "white", children: "Refresh page" })
        }
      )
    }
  );
};
export {
  G as PageNotFound,
  S as SomethingWentWrong
};
