import { lazy as e } from "react";
const s = {
  primary: e(() => import("../../page-primary-D9PAtxGT.js")),
  gray: e(() => import("../../page-gray-2VKyd2Rn.js")),
  solid: e(() => import("../../page-solid-DpzwDKTJ.js"))
}, d = {
  folder: "brand",
  image: "brand",
  video: "blueDark",
  "video-2": "blueDark",
  document: "blueDark",
  audio: "pink",
  pdf: "error",
  spreadsheet: "success",
  code: "indigo"
}, r = {
  img: "brand",
  jpg: "brand",
  jpeg: "brand",
  png: "brand",
  webp: "brand",
  tiff: "brand",
  gif: "brand",
  svg: "brand",
  eps: "brand",
  pdf: "error",
  doc: "blueDark",
  docx: "blueDark",
  odt: "blueDark",
  txt: "gray",
  csv: "success",
  xls: "success",
  xlsx: "success",
  ppt: "orangeDark",
  pptx: "orangeDark",
  fig: "brand",
  ai: "orange",
  psd: "blueDark",
  indd: "fuchsia",
  aep: "purple",
  mp3: "pink",
  wav: "pink",
  mp4: "blueDark",
  mpeg: "blueDark",
  avi: "blueDark",
  mkv: "blueDark",
  html: "indigo",
  css: "indigo",
  rss: "indigo",
  sql: "indigo",
  js: "indigo",
  json: "indigo",
  java: "indigo",
  xml: "indigo",
  exe: "indigo",
  dmg: "indigo",
  zip: "gray",
  rar: "gray"
}, c = {
  folder: "folder",
  image: "image-05",
  video: "film-01",
  "video-2": "play-circle",
  document: "document",
  audio: "music-note-01",
  pdf: "portable-document-format",
  spreadsheet: "spreadsheet",
  code: "code-02"
}, a = {
  img: "application/octet-stream",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  webp: "image/webp",
  tiff: "image/tiff",
  gif: "image/gif",
  svg: "image/svg+xml",
  eps: "application/postscript",
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  odt: "application/vnd.oasis.opendocument.text",
  txt: "text/plain",
  csv: "text/csv",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  fig: "application/octet-stream",
  ai: "application/postscript",
  psd: "image/vnd.adobe.photoshop",
  indd: "application/x-indesign",
  aep: "application/vnd.adobe.aftereffects.project",
  mp3: "audio/mpeg",
  wav: "audio/wav",
  mp4: "video/mp4",
  mpeg: "video/mpeg",
  avi: "video/x-msvideo",
  mkv: "video/x-matroska",
  html: "text/html",
  css: "text/css",
  rss: "application/rss+xml",
  sql: "application/sql",
  js: "application/javascript",
  json: "application/json",
  java: "text/x-java-source",
  xml: "application/xml",
  exe: "application/vnd.microsoft.portable-executable",
  dmg: "application/x-apple-diskimage",
  zip: "application/zip",
  rar: "application/x-rar-compressed"
}, m = Object.keys(a).reduce((o, p) => {
  const i = p, t = a[i];
  return {
    ...o,
    [t]: i
  };
}, {});
export {
  r as FILE_ICON_EXTENSION_COLOR_MAPPER,
  a as FILE_ICON_EXTENSION_MIME_MAPPER,
  m as FILE_ICON_MIME_EXTENSION_MAPPER,
  d as FILE_ICON_TYPE_COLOR_MAPPER,
  c as FILE_TYPE_ICON_MAPPER,
  s as VARIANT_PAGE_MAPPER
};
