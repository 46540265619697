import { a as t } from "../../radii-Bwe-6nor.js";
import { a } from "../../spacings-B-jE7bvu.js";
import { a as e } from "../../widths-zxbxSVTf.js";
import { a as o } from "../../effects-Bfy0hCtz.js";
import m from "./zIndexes.es.js";
import { a as r } from "../../breakpoints-B8hcA4T_.js";
import { l as i, d as s } from "../../palettes-Dfhobg_2.js";
const p = {
  id: "defaultLightTheme",
  mode: "light",
  palette: i,
  spacings: a,
  widths: e,
  radii: t,
  effects: o,
  zIndexes: m,
  breakpoints: r,
  components: {}
}, E = {
  ...p,
  id: "defaultDarkTheme",
  mode: "dark",
  palette: s,
  components: {}
};
export {
  E as DEFAULT_DARK_THEME,
  p as DEFAULT_LIGHT_THEME
};
