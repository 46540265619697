import { j as a } from "../../jsx-runtime-DmTjM30f.js";
import l from "styled-components";
import { L as x } from "../../Logomark-CSkGOsB3.js";
import { c as m, b as c } from "../../Dropdown-BVLrM-4x.js";
const v = l.div`
  display: flex;
  flex-flow: row;
  
  align-items: center;
  
  gap: 16px;
  
  > *:first-child {
    display: none;
  }
  
  div.spacer {
    flex: 1;
  }
  
  padding: 0 24px;
  
  @media (max-width: 1024px) {
    padding: 12px 8px 12px 16px;
    
    > *:first-child {
      display: unset;
    }
  }
`, j = (t) => {
  const {
    product: e,
    userLoginEmail: p,
    userDisplayName: i,
    leftAlignedChildren: d,
    rightAlignedChildren: n,
    dropdownChildren: o,
    className: r,
    ...s
  } = t;
  return /* @__PURE__ */ a.jsxs(v, { ...s, className: r, children: [
    /* @__PURE__ */ a.jsx(x, { variant: e }),
    d,
    /* @__PURE__ */ a.jsx("div", { className: "spacer" }),
    n,
    /* @__PURE__ */ a.jsxs(
      m,
      {
        ...s,
        className: r,
        variant: "avatar",
        avatarProps: {
          variant: "initials",
          displayName: i
        },
        children: [
          /* @__PURE__ */ a.jsx(
            c,
            {
              variant: "avatar",
              avatar: {
                text: i,
                supportingText: p,
                avatar: {
                  variant: "initials",
                  displayName: i
                }
              }
            }
          ),
          o
        ]
      }
    )
  ] });
};
export {
  j as default
};
