import { useState as m, useEffect as h } from "react";
import { useTheme as p } from "styled-components";
import { getBreakpoint as f } from "./utils.es.js";
const n = (e) => typeof window < "u" ? window.matchMedia(e).matches : !1, u = (e, o) => e === "mobile" || e === "desktop" ? f("max", "lg")({
  theme: o
}) : e, w = (e) => {
  const o = p(), t = u(e, o), a = e === "desktop" ? !n(t) : n(t), [
    c,
    i
  ] = m(a);
  return h(() => {
    const s = window.matchMedia(t), r = () => {
      const d = e === "desktop" ? !n(t) : n(t);
      i(d);
    };
    return s.addEventListener("change", r), () => {
      s.removeEventListener("change", r);
    };
  }, [t]), c;
};
export {
  w as useMediaQuery
};
